.main-container {
    background-color: #282c34;

    &__welcome {
        background-color: #282c34;
        margin-top: 0;
        color: #ffffff;
        text-align: center;
        font-size: calc(12px + 2vmin);
    }
}

.hero-container-image {
    width: 100vw;
    height: 56vw;
    object-fit: cover;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(12px + 2vmin);
    color: #BDC5D5;
    // background-color: aqua;
    background-color: black;
    padding-top: 3%;
    padding-bottom: 3%;

    &-main-content {
        display: flex;
        flex-direction: column;

        .message-input {

            padding-bottom: 2.6rem;
        }
    }

    &-main-content>* {
        width: 50vw;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    input {
        border: 1px solid #BDC5D5;
        border-radius: 20px;
    }

    &-buttons-container {
        display: flex;
        justify-content: space-between;
        padding-top: 1%;
        padding-bottom: 1%;

        .button {
            width: 15vw;
            color: black;
            border: 1px solid #BDC5D5;
            border-radius: 20px;
            margin-right: 5vw;
            padding-top: 0.7vw;
            padding-bottom: 0.7vw;

        }

        .button:last-child {
            margin-right: 0;
        }
    }

}
