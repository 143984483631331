.container {
    text-align: left;
    background-color: #282c34;
    padding-left: 2%;

    min-height: 100vh;
    display: flex;
    flex-direction: column;

    font-size: calc(10px + 2vmin);
    color: #ffffff;

    justify-content: center;
    align-items: flex-start;

    &-title {
        align-self: center;
    }
}

.hero-container__image {
    width: 50vw;
    height: 40vw;
    object-fit: cover;


}
.meth-main-container{
    display: flex;
    flex-direction: row;
}
