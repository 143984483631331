.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    color: white;
}
.social-container{
    justify-self: flex-end;
}

.footer {
    text-align: center;
}

.email-link {
    text-decoration: none;
    color: #ffffff;
}

.social-icons-container {
    display: flex;
    justify-content: space-evenly;
}

.copyright-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #ffffff;

    img {
        width: 3vw;
        height: 2vw;
        background-color: #ffffff;
    }

    p {
        color: #ffffff;
    }
}
