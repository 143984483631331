.nav {

    background-color: rgb(36, 26, 26);
    // background-color: #f4f414;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(13px + 2vmin);

    &-logo__container {
        display: flex; 
        margin-left: calc(-7vw + 2%);
    }

    a {
        text-decoration: none;
        font-weight: 700;
        color: #ffffff;
    }
}

.main-logo {
    width: calc(12vw + 2vmin);
    // height: 40vmin;
    pointer-events: none;
    align-self: flex-start;
}
