.main {
    text-align: left;
}



.main-header {
    background-color: #282c34;

    min-height: 100vh;
    display: flex;
    flex-direction: column;

    font-size: calc(10px + 2vmin);
    color: #ffffff;


}

.main-heading {
    align-self: center;
    font-size: 1.5em;
}

.main-content-container {
    display: flex;
    flex-direction: row;

}

.hero-container__image {

    width: calc(50vw + 2vmin);

}

.main-content {
    text-align: left;

    P {
        padding-left: 1%;
        padding-right: 1%;

        img {
            padding-left: 2%;
            padding-right: 2%;
            border-radius: 5px;
        }
    }

}
